<template>
  <form
    ref="examus"
    :action="action"
    method="post"
  >
    <input
      type="hidden"
      name="token"
      :value="token"
    >
  </form>
</template>

<script>

export default {
  name: 'ExamusProctoring',

  props: {
    url: {
      type: String,
      default: ''
    },

    token: {
      type: String,
      default: ''
    },
  },

  computed: {
    action () {
      return this.url + '/start/'
    }
  },

  mounted () {
    this.submit()
  },

  created () {
    // Если вернулись на страницу кнопкой 'Назад' форма может не отправиться, обработаем этот случай отдельно
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        this.submit()
      }
    })
  },

  methods: {
    submit () {
      if (this.$route.query?.proctoring_started === '1') {
        return false
      }

      this.$refs.examus.submit()
    }
  }
}
</script>
